
export function parseDate(date){
    return new Date(date + "T00:00:00")
}

export function dateDiff(date1, date2){
    return (parseDate(date1) - parseDate(date2)) / (1000*60*60*24)
}

export function todayDiff(date){
    return (parseDate(date).getTime() - Date.now()) / (1000*60*60*24)
}