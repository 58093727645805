<template >
  <v-layout>
    <v-main>
      <v-row class="justify-center mt-5">
        <v-card width="1175px" class="mt-3" flat color="#f1f1f1">
          <v-card-text>
            <h2 class="evisa-header mb-5">{{ $t("tracking.title") }}</h2>
            <v-alert
              border="bottom"
              colored-border
              :type="alertType"
              elevation="2"
              dismissible
              v-model="alert"
            >
              <h2 class="title">{{ $t("tracking.title") }}</h2>
              <div>
                {{ message }}
              </div>
            </v-alert>
            <v-container grid-list-md class="col-6">
              <v-form ref="checkForm">
                <v-layout wrap>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      :label="$t('tracking.label1')"
                      :placeholder="$t('tracking.label1Tooltip')"
                      outlined
                      dense
                      required
                      :rules="requiredRules"
                      v-model="payload.trackingNumber"
                      background-color="#FFF"
                      class="mt-5"
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm12 md12>
                    <v-text-field
                      :label="$t('tracking.label2')"
                      :placeholder="$t('tracking.label2Tooltip')"
                      outlined
                      dense
                      required
                      :rules="mailRules"
                      v-model="payload.email"
                      background-color="#FFF"
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-form>
              * <small>{{ $t("requiredField") }}</small>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-container class="col-6 text-right">
              <v-btn
                right
                class="ma-2 white--text"
                rounded
                color="teal"
                @click="check()"
              >
                <v-icon>mdi-check</v-icon> {{ $t("tracking.submit") }}
              </v-btn>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>
<script>
import FooterInfo from "@/components/FooterInfo";
import ApplicationService from "@/services/ApplicationService";
export default {
  components: {
    FooterInfo,
  },
  data() {
    return {
      mailRules: [
        (v) => !!v || "E-mail est obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit etre valide",
      ],
      requiredRules: [(v) => !!v || this.$t("requiredField")],
      payload: {
        email: "",
        trackingNumber: "",
      },
      alertType: "",
      alert: false,
      message: "",
    };
  },
  methods: {
    check() {
      if (this.$refs.checkForm.validate()) {
        ApplicationService.checkStatus(this.payload)
          .then((response) => {
            if (response.status == 200) {
              this.message = this.getMessage(response.data);
            } else {
              this.message =
                this.$t("finalStep.warningText") +
                " " +
                this.$t("finalStep.warningLink");
              this.alertType = "error";
              this.alert = true;
            }
          })
          .catch(() => {
            this.message = this.$t("tracking.notfound");
            this.alertType = "error";
              this.alert = true;
          });
      }
    },

    getMessage(status) {
      if (status === "") {
        this.alertType = "primary";
        this.alert = true;
        return this.$t("tracking.pending");
      }

      if (status === false) {
        this.alertType = "warning";
        this.alert = true;
        return this.$t("tracking.rejected");
      }

      if (status === true) {
        this.alertType = "success";
        this.alert = true;
        return this.$t("tracking.accepted");
      }
    },
  },
};
</script>