<template>
  <v-layout>
    <v-main style="height:auto">
        <v-carousel
          width="100%"
          height="100%"
          cycle
          hide-delimiter-background
          hide-delimiters
          :show-arrows="false"
        >
          <v-carousel-item v-for="(item, i) in items" :key="i" :src="item.src">
            <v-row class="fill-height" align="center">
              <caroussel-btn></caroussel-btn>
            </v-row>
          </v-carousel-item>
        </v-carousel>
    </v-main>
    <v-footer app dark class="px-0">
      <home-footer></home-footer>
    </v-footer>
  </v-layout>
</template>

<script>
import CarousselBtn from "@/components/CarousselBtn";
import HomeFooter from "@/components/HomeFooter";
export default {
  name: "HomePage",
  components: {
    CarousselBtn,
    HomeFooter,
  },
  data() {
    return {
      items: [
        {
          src: require("../assets/djibouti1.jpg"),
        },
        {
          src: require("../assets/djibouti2.jpg"),
        },
        {
          src: require("../assets/djibouti3.jpg"),
        },
        {
          src: require("../assets/djibouti4.jpg"),
        },
        {
          src: require("../assets/djibouti5.jpg"),
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>