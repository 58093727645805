import axios from 'axios'

const API_URL = process.env.VUE_APP_MSPAY_URL

class PaymentService{
    checkout(payload){
        return axios.post(`${API_URL}/checkout`, payload)
    }

}

export default new PaymentService()