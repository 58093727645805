<template class="justify-center">
  <v-row class="justify-center mt-5">
    <v-card width="800px">
      <v-card-title class="headline white justify-left">
        <span class="headline black--text">{{
          $t("footerContact.title")
        }}</span>
      </v-card-title>

      <v-card-text style="height: 350px">
        <v-container grid-list-md>
          <v-form ref="formDemande">
            <v-layout wrap>
              <v-flex xs12 sm6 md6>
                <v-text-field
                 filled
                  outlined
                  dense
                  :label="$t('footerContact.name')"
                  :placeholder="$t('footerContact.namePlacehold')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md6>
                <v-text-field
                 filled
                  outlined
                  dense
                  :label="$t('footerContact.firstName')"
                  :placeholder="$t('footerContact.firstNamePlacehold')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field
                  filled
                  outlined
                  dense
                  :label="$t('footerContact.mailAddress')"
                  :placeholder="$t('footerContact.mailAddressPlacehold')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field
                 filled
                  outlined
                  dense
                  :label="$t('footerContact.subject')"
                  :placeholder="$t('footerContact.subjectPlacehold')"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-textarea
                  filled
                  outlined
                  dense
                  auto-grow
                  rows="3"
                  :label="$t('footerContact.message')"
                  :placeholder="$t('footerContact.messagePlacehold')"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-form>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <div class="col-12">
          <v-flex xs12 sm12 md12>
            <v-btn class="mb-2" block color="success" outlined>
              {{ $t("footerContact.submit") }}
            </v-btn>
          </v-flex>
        </div>
      </v-card-actions>
    </v-card>
  </v-row>
</template>
<script>
export default {
  name: "ContactForm",

  data: () => ({
    //
  }),
};
</script>