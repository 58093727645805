<template>
  <v-layout>
    <v-main>
      <v-row class="justify-center mt-5">
        <v-card width="1175px" class="" outlined color="#f1f1f1">
          <v-card-text>
            <h2 class="evisa-header mb-5">{{ $t("legalNotice.title") }}<br/>{{ $t("legalNotice.subtitle") }}</h2>
            <div class="text-justify" v-for="(item,i) in $t('legalNotice.mentions')" :key="i">
              <h2 class="mb-2">
                {{ item.topicTitle }}
              </h2>
              <p class="mb-10">
                {{ item.topicText }}
              </p>
            </div>
            
          </v-card-text>
        </v-card>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>

<script>
import FooterInfo from "@/components/FooterInfo";
export default {
  components: {
    FooterInfo,
  },

};
</script>