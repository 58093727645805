<template>
  <v-app-bar  color="#333333" height="95" dark app fixed> 
    <div class="col-6 text-center" style="cursor: pointer" @click="home">
      <v-icon></v-icon>
      <img class="mr-8 mt-2" src="../assets/evisa-logo.png" height="72px" />
    </div>

    <v-spacer></v-spacer>

    <div class="col-6 text-center">
      <v-menu offset-y class="mt-4 ml-3">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            width="auto"
            height="auto"
            class="pa-1"
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            <v-avatar height="65" width="65"
              ><img src="../assets/world.png"
            /></v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="item in langs" :key="item.code" class="ite">
            <v-list-item-title @click="changeLocale(item.code)" c>{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <span style="color: #d3d3d3; font-size: 27px"
        ><strong>{{ this.$i18n.locale }}</strong></span
      >
    </div>
  </v-app-bar>
</template>
<script>
export default {
  name: "Header",
  props: {
    image_url: String,
  },
  data: () => ({
    langs: [
      { title: "English", code: "EN" },
      { title: "Français", code: "FR" },
    ],
  }),
  methods: {
    home() {
      this.$router.push("/");
    },

    changeLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem("locale", this.$i18n.locale);
      this.$vuetify.lang.current = this.$i18n.locale;
    },

    getLocale() {
      if (localStorage.getItem("locale") != null) {
        this.$i18n.locale = localStorage.getItem("locale");
        this.$vuetify.lang.current = this.$i18n.locale;
      }
    },
  },
  mounted() {
    this.getLocale();
  },
};
</script>
<style scoped>
.ite{
  cursor: pointer;
}
</style>