export default class Applicant{
    constructor(actualNationality,birthdate,email,gender,maritalStatus,name,originNationality,job,surname, birthstate, birthplace){
        this.actualNationality = actualNationality
        this.birthdate = birthdate
        this.email = email
        this.gender = gender
        this.maritalStatus = maritalStatus
        this.name =name
        this.originNationality = originNationality
        this.job = job
        this.surname =surname
        this.birthplace = birthplace
        this.birthstate = birthstate
    }

}