<template>
  <v-layout>
    <v-main>
      <v-row class="px-3">
        <v-col md="8" offset-md="2">
          <v-card class="my-5">
            <v-stepper v-model="step">
              <v-stepper-header>
                <v-stepper-step :complete="step > 1" step="1">
                  {{ $t("stepOne.title") }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2">
                  {{ $t("stepTwo.title") }}
                </v-stepper-step>
                <v-divider></v-divider>

                <v-stepper-step :complete="step > 3" step="3">
                  {{ $t("stepTwo.subtitle1") }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 4" step="4">
                  <span v-html="$t('stepThree.title')"></span>
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 5" step="5">
                  {{ $t("resumeStep.title") }}
                </v-stepper-step>
              </v-stepper-header>

              <!-- Début Informations voyage -->
              <v-stepper-content step="1">
                <v-alert
                  border="bottom"
                  v-model="alert"
                  dense
                  color="red"
                  colored-border
                  type="error"
                  elevation="2"
                  dismissible
                >
                  {{ errorMessage }}
                </v-alert>
                <v-form
                  ref="journeyInfo"
                  v-model="journeyValid"
                  lazy-validation
                >
                  <v-card>
                    <v-card-text>
                      <h2 class="mtitle pb-2">{{ $t("stepOne.subtitle1") }}</h2>
                      <v-row>
                        <v-col class="mt-2">
                          <!-- passeportNumber -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepOne.numPass.name')"
                                
                                hide-details="auto"
                                v-model="passeport.number"
                                outlined
                                dense
                                filled
                                :rules="requiredRules"
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepOne.numPass.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- delivryState -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepOne.originPass.name')"
                                v-model="passeport.delivryState"
                                hide-details="auto"
                                outlined
                                filled
                                dense
                                :rules="requiredRules"
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepOne.originPass.tooltip')}}</span>
                          </v-tooltip>
                          
                        </v-col>
                        <v-col class="mt-2">
                          <!-- delivryMenu -->
                          <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                            <v-menu
                              ref="delivryMenu"
                              v-model="delivryMenu"
                              :close-on-content-click="false"
                              transition="scale-transition *"
                              offset-y
                              min-width="auto"
                              hide-details="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="passeport.delivryDate"
                                  :label="$t('stepOne.EmDate.name')"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  v-on:change="delivryDateChoice"
                                  hide-details="auto"
                                  outlined
                                  filled
                                  dense
                                  class="mb-2"
                                  :rules="requiredRules"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="passeport.delivryDate"
                                :active-picker.sync="activePicker"
                                :max="
                                  new Date(
                                    Date.now() -
                                      new Date().getTimezoneOffset() * 60000
                                  )
                                    .toISOString()
                                    .substr(0, 10)
                                "
                                min="1920-01-01"
                                @change="saveDelivryDate"
                              ></v-date-picker>
                            </v-menu>
                            </span>
                          </template>
                          <span>{{$t('stepOne.EmDate.tooltip')}}</span>
                        </v-tooltip>
                          
                          <!-- expiredMenu -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-menu
                                ref="expiredMenu"
                                v-model="expiredMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="passeport.expiredDate"
                                    :label="$t('stepOne.ExDate.name')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    filled
                                    dense
                                    :rules="requiredRules"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="passeport.expiredDate"
                                  :active-picker.sync="activePicker"
                                  :min="
                                    new Date(Date.now() + 1000 * 60 * 60 * 24 * 94)
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  @change="saveExpiredDate"
                                ></v-date-picker>
                              </v-menu>
                              </span>
                            </template>
                            <span>{{$t('stepOne.ExDate.tooltip')}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>

                      <h2 class="pb-2 mt-8 mtitle">
                        {{ $t("stepOne.title") }}
                      </h2>
                      <v-row>
                        <v-col class="mt-2">
                          <!-- journeyMotive -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-select
                                :items="$t('stepOne.reasonList')"
                                :label="$t('stepOne.reason.name')"
                                v-model="journey.reason"
                                hide-details="auto"
                                outlined
                                filled
                                dense
                                class="mb-2"
                                :rules="requiredRules"
                              ></v-select>
                              </span>
                            </template>
                            <span>{{$t('stepOne.reason.tooltip')}}</span>
                          </v-tooltip>
                          
                        </v-col>
                        <v-col class="mt-2">
                          <!-- enterDate -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-menu
                                ref="menuEnter"
                                v-model="menuEnter"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="journey.entryDate"
                                    :label="$t('stepOne.arrivalDate.name')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    filled
                                    dense
                                    class="mb-2"
                                    :rules="requiredRules"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="journey.entryDate"
                                  :active-picker.sync="activePicker"
                                  :min="
                                    new Date(Date.now() + 345600000)
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  @change="saveEnterDate"
                                ></v-date-picker>
                              </v-menu>
                              </span>
                            </template>
                            <span>{{$t('stepOne.arrivalDate.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- exitdate -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-menu
                                ref="menuExit"
                                v-model="menuExit"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="journey.exitDate"
                                    :label="$t('stepOne.departureDate.name')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    outlined
                                    filled
                                    dense
                                    :rules="requiredRules"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="journey.exitDate"
                                  :active-picker.sync="activePicker"
                                  :min="
                                    new Date(Date.now() + 345600000)
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  @change="saveExitDate"
                                ></v-date-picker>
                              </v-menu>
                              </span>
                            </template>
                            <span>{{$t('stepOne.departureDate.tooltip')}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row class="mt-10">
                        <p>* {{ $t("requiredField") }}</p>
                      </v-row>
                      <v-row class="pt-5">
                        <v-btn
                          color="success"
                          @click="validateJourney()"
                          class="ml-auto"
                        >
                          {{ $t("buttonsForm.next.name") }}
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-stepper-content>
              <!-- Fin Informations voyage -->

              <!-- Début Informations personnelles -->
              <v-stepper-content step="2">
                <v-form ref="personalInfo" v-model="infoValid" lazy-validation>
                  <v-card>
                    <v-card-text>
                      <h2 class="pb-2 mtitle">{{ $t("stepTwo.title") }}</h2>

                      <v-row>
                        <v-col class="mt-2">
                          <!-- name -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.name.name')"
                                hide-details="auto"
                                v-model="applicant.name"
                                :rules="requiredRules"
                                dense
                                outlined
                                class="mb-2"
                                filled
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.name.tooltip')}}</span>
                          </v-tooltip>
                          <!-- surname -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.firstname.name')"
                                hide-details="auto"
                                v-model="applicant.surname"
                                :rules="requiredRules"
                                dense
                                outlined
                                class="mb-2"
                                filled
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.firstname.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- sexe -->
                          <v-radio-group
                            v-model="applicant.gender"
                            :label="$t('stepTwo.gender.name')"
                            row
                            hide-details="auto"
                            mandatory
                            class="mb-2"
                          >
                            <v-radio
                              :label="$t('stepTwo.genderMale')"
                              value="M"
                            ></v-radio>
                            <v-radio
                              :label="$t('stepTwo.genderFemale')"
                              value="F"
                            ></v-radio>
                          </v-radio-group>
                          <!-- matrimonial status -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-select
                                :items="$t('stepTwo.FamilySituationList')"
                                :label="$t('stepTwo.FamilySituation.name')"
                                hide-details="auto"
                                v-model="applicant.maritalStatus"
                                dense
                                outlined
                                class="mb-2"
                                filled
                                :rules="requiredRules"
                              ></v-select>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.FamilySituation.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- job -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.job.name')"
                                hide-details="auto"
                                v-model="applicant.job"
                                :rules="requiredRules"
                                dense
                                outlined
                                filled
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.job.tooltip')}}</span>
                          </v-tooltip>
                          
                        </v-col>
                        <!-- birthdate -->
         
                        <v-col class="mt-2">
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-menu
                                ref="birthDateMenu"
                                v-model="birthDateMenu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                min-width="auto"
                                hide-details="auto"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field
                                    v-model="applicant.birthdate"
                                    :label="$t('stepTwo.birthDate.name')"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details="auto"
                                    dense
                                    outlined
                                    class="mb-2"
                                    filled
                                    :rules="requiredRules"
                                  ></v-text-field>
                                </template>
                                <v-date-picker
                                  v-model="applicant.birthdate"
                                  :active-picker.sync="activePicker"
                                  :max="
                                    new Date(
                                      Date.now() -
                                        new Date().getTimezoneOffset() * 60000
                                    )
                                      .toISOString()
                                      .substr(0, 10)
                                  "
                                  min="1920-01-01"
                                  @change="savebirthDate"
                                ></v-date-picker>
                              </v-menu>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.birthDate.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- birthstate -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepTwo.birthCountry.name')"
                                v-model="applicant.birthstate"
                                hide-details="auto"
                                :rules="requiredRules"
                                dense
                                outlined
                                class="mb-2"
                                filled
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.birthCountry.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- birthplace -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.birthPlace.name')"
                                hide-details="auto"
                                v-model="applicant.birthplace"
                                :rules="requiredRules"
                                dense
                                outlined
                                class="mb-2"
                                filled
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.birthPlace.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- actualNationality -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepTwo.actualNationality.name')"
                                v-model="applicant.actualNationality"
                                hide-details="auto"
                                :rules="requiredRules"
                                filled
                                dense
                                outlined
                                class="mb-2"
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.actualNationality.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- originNationality -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepTwo.OriginNationality.name')"
                                v-model="applicant.originNationality"
                                hide-details="auto"
                                :rules="requiredRules"
                                dense
                                outlined
                                filled
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.OriginNationality.tooltip')}}</span>
                          </v-tooltip>
                          
                        </v-col>
                      </v-row>
                      <v-row class="mt-10">
                        <p>* {{ $t("requiredField") }}</p>
                      </v-row>
                      <v-row class="pt-5">
                        <v-btn @click="previous(1)">
                          <v-icon>mdi-chevron-left</v-icon>
                          {{ $t("buttonsForm.previous.name") }}
                        </v-btn>
                        <v-btn
                          color="success"
                          @click="validatePerso"
                          class="ml-auto"
                        >
                          {{ $t("buttonsForm.next.name") }}
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-stepper-content>
              <!-- end Sinformations personnelles -->

              <!-- Début Informations complementaires -->
              <v-stepper-content step="3">
                <v-form ref="compInfo" v-model="compValid" lazy-validation>
                  <v-card>
                    <v-card-text>
                      <h2 class="pb-2 mtitle">{{ $t("stepTwo.subtitle1") }}</h2>

                      <v-row>
                        <v-col class="mt-2">
                          <!-- Adresse -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.actualAddress.name')"
                                hide-details="auto"
                                v-model="contact.line"
                                :rules="requiredRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.actualAddress.tooltip')}}</span>
                          </v-tooltip>
                          <!-- city -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.actualCity.name')"
                                hide-details="auto"
                                v-model="contact.city"
                                :rules="requiredRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.actualCity.tooltip')}}</span>
                          </v-tooltip>
                          <!-- cp -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.postalCode.name')"
                                hide-details="auto"
                                v-model="contact.cp"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.postalCode.tooltip')}}</span>
                          </v-tooltip>
                          <!-- residentState -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepTwo.actualCountry.name')"
                                hide-details="auto"
                                v-model="contact.state"
                                :rules="requiredRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.actualCountry.tooltip')}}</span>
                          </v-tooltip>
                          <!-- message additional -->
                        </v-col>
                        <v-col class="mt-2">
                          <!-- applicationState -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepTwo.countryOfApplication.name')"
                                hide-details="auto"
                                v-model="application.originState"
                                :rules="requiredRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.countryOfApplication.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- phone -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.phone.name')"
                                hide-details="auto"
                                v-model="contact.phone"
                                :rules="numberRequiredRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.phone.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- mail -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.mailAddress.name')"
                                hide-details="auto"
                                v-model="applicant.email"
                                :rules="mailRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.mailAddress.tooltip')}}</span>
                          </v-tooltip>
                          <!-- confirmation mail -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepTwo.mailAddressConfirm.name')"
                                hide-details="auto"
                                v-model="confirmMail"
                                :rules="cmailRules"
                                filled
                                outlined
                                dense
                                class="mb-2"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.mailAddressConfirm.tooltip')}}</span>
                          </v-tooltip>
                          
                          <!-- pays visité -->
                        </v-col>
                      </v-row>
                      <v-row class="justify-center">
                        <v-col cols="8">
                          <!-- stay country -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-autocomplete
                                :items="countries"
                                item-value="id"
                                item-text="fr"
                                :label="$t('stepTwo.lastVisitedCountries')"
                                hide-details="auto"
                                v-model="application.lastVisitedCountries"
                                multiple
                                small-chips
                                dense
                                filled
                                outlined
                                class="mb-2"
                              ></v-autocomplete>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.lastVisitedCountries')}}</span>
                          </v-tooltip>
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-textarea
                                filled
                                auto-grow
                                height="100px"
                                :label="$t('stepTwo.optionalText.name')"
                                outlined
                                dense
                                class="mb-2"
                                v-model="application.comment"
                              ></v-textarea>
                              </span>
                            </template>
                            <span>{{$t('stepTwo.optionalText.name')}}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row class="mt-10">
                        <p>* {{ $t("requiredField") }}</p>
                      </v-row>
                      <v-row class="pt-5">
                        <v-btn @click="previous(2)">
                          <v-icon>mdi-chevron-left</v-icon>
                          {{ $t("buttonsForm.previous.name") }}
                        </v-btn>
                        <v-btn
                          color="success"
                          @click="validateCompl"
                          class="ml-auto"
                        >
                          {{ $t("buttonsForm.next.name") }}
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-stepper-content>
              <!-- end Sinformations complementaires -->
              <!-- begin justificatif 3 -->
              <v-stepper-content step="4">
                <v-form lazy-validation ref="addrInfo" v-model="addrValid">
                  <v-card>
                    <v-card-text>
                      <h2 class="pb-2 mtitle">
                        {{ $t("stepThree.subtitle1") }}
                      </h2>
                      <v-row>
                        <v-col class="mt-2">
                          <!-- Adresse -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepThree.stayAddress.name')"
                                hide-details="auto"
                                filled
                                outlined
                                v-model="journey.stayAddress"
                                dense
                                class="mb-2"
                                :rules="requiredRules"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepThree.stayAddress.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- city -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                              :label="$t('stepThree.stayCity.name')"
                              hide-details="auto"
                              filled
                              outlined
                              v-model="journey.stayCity"
                              dense
                              :rules="requiredRules"
                            ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepThree.stayCity.tooltip')}}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col class="mt-2">
                          <!-- Téléphone -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-text-field
                                :label="$t('stepThree.stayPhone.name')"
                                hide-details="auto"
                                filled
                                outlined
                                v-model="journey.stayPhone"
                                dense
                                :rules="numberRequiredRules"
                              ></v-text-field>
                              </span>
                            </template>
                            <span>{{$t('stepThree.stayPhone.tooltip')}}</span>
                          </v-tooltip>                         
                        </v-col>
                      </v-row>

                      <h2 class="pb-2 mt-8 mtitle">
                        {{ $t("stepThree.subtitle2") }}
                      </h2>

                      <v-row>
                        <v-col cols="7">
                          <!-- photo -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-file-input
                                :rules="imgRules"
                                v-model="files.photo"
                                accept="image/png, image/jpeg"
                                :label="$t('stepThree.identityPicture.name')"
                                show-size
                                prepend-icon="mdi-camera"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                                truncate-length="15"
                              ></v-file-input>
                              </span>
                            </template>
                            <span>{{$t('stepThree.identityPicture.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- passeport picture -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-file-input
                                :rules="fileRules"
                                v-model="files.passport"
                                accept="image/png, image/jpeg, .pdf"
                                :label="$t('stepThree.passportPicture.name')"
                                show-size
                                truncate-length="15"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                              ></v-file-input>
                              </span>
                            </template>
                            <span>{{$t('stepThree.passportPicture.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- ticket picture -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-file-input
                                :rules="fileRules"
                                v-model="files.ticket"
                                accept="image/png, image/jpeg, .pdf"
                                :label="$t('stepThree.invitationPicture.name')"
                                show-size
                                truncate-length="15"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                              ></v-file-input>
                              </span>
                            </template>
                            <span>{{$t('stepThree.invitationPicture.tooltip')}}</span>
                          </v-tooltip>
                          

                          <!-- reservation picture -->
                          <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                              <v-file-input
                                :rules="fileRules"
                                v-model="files.reservation"
                                accept="image/png, image/jpeg, .pdf"
                                :label="$t('stepThree.invitationLetter.name')"
                                show-size
                                truncate-length="15"
                                filled
                                outlined
                                dense
                                class="mb-2"
                                hide-details="auto"
                              ></v-file-input>
                              </span>
                            </template>
                            <span>{{$t('stepThree.invitationLetter.tooltip')}}</span>
                          </v-tooltip>
                          
                        </v-col>
                        <!-- Text fichier passport -->
                        <v-col cols="4" class="ml-15">
                          <p class="text-justify">
                            {{ $t("stepThree.fileDesc.identityPicture") }}<br />
                            {{ $t("stepThree.fileDesc.type") }}<br />
                            {{ $t("stepThree.fileDesc.size") }}<br />
                            {{ $t("stepThree.fileDesc.letter") }}
                          </p>
                        </v-col>
                      </v-row>
                      <v-row class="pt-5">
                        <v-btn @click="previous(3)"
                          ><v-icon>mdi-chevron-left</v-icon>
                          {{ $t("buttonsForm.previous.name") }}
                        </v-btn>
                        <v-btn
                          color="success"
                          @click="validateAddr"
                          class="ml-auto"
                        >
                          {{ $t("buttonsForm.next.name") }}
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-form>
              </v-stepper-content>
              <!-- end justificatifs -->

              <!-- recapitulatif -->
              <v-stepper-content step="5">
                <v-card>
                  <v-card-text>
                    <h2 class="pb-2 mb-3 mtitle">{{ $t("stepOne.title") }}</h2>
                    <v-row class="mb-5">
                      <v-col>
                        <v-row>
                          <v-col>
                            <v-text-field
                              :label="$t('stepOne.numPass.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="passeport.number"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepOne.originPass.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getCountry(passeport.delivryState)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>

                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepOne.EmDate.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="passeport.delivryDate"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepOne.ExDate.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="passeport.expiredDate"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>

                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepOne.reason.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getReason(journey.reason)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepOne.arrivalDate.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="journey.entryDate"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepOne.departureDate.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="journey.exitDate"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col class="row mt-1 ml-0" @click="previous(1)">
                            <v-btn color="success" class="ml-auto">{{
                              $t("buttonsForm.modify.name")
                            }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <h2 class="mtitle pb-2">{{ $t("stepTwo.title") }}</h2>
                    <v-row class="mb-5">
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.name.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="applicant.name"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.firstname.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="applicant.surname"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.gender.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getGender(applicant.gender)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.FamilySituation.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getMarital(applicant.maritalStatus)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.job.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="applicant.job"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.birthDate.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="applicant.birthdate"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.birthCountry.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getCountry(applicant.birthstate)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.birthPlace.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="applicant.birthplace"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.actualNationality.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getCountry(applicant.actualNationality)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.OriginNationality.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getCountry(applicant.originNationality)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col class="row mt-1 ml-0" @click="previous(2)">
                            <v-btn color="success" class="ml-auto">{{
                              $t("buttonsForm.modify.name")
                            }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <h2 class="pb-2 mtitle">{{ $t("stepTwo.subtitle2") }}</h2>
                    <v-row class="mb-5">
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.actualAddress.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="contact.line"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.actualCity.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="contact.city"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.postalCode.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="contact.cp"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.actualCountry.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getCountry(applicant.actualNationality)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.countryOfApplication.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getCountry(application.originState)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.phone.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="contact.phone"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepTwo.mailAddress.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="applicant.email"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-autocomplete
                              :items="countries"
                              item-value="id"
                              item-text="fr"
                              :label="$t('stepTwo.lastVisitedCountries')"
                              :placeholder="$t('stepTwo.lastVisitedCountries')"
                              hide-details="auto"
                              v-model="application.lastVisitedCountries"
                              multiple
                              readonly
                              small-chips
                              dense
                              filled
                              outlined
                            ></v-autocomplete
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-textarea
                              filled
                              auto-grow
                              rows="4"
                              :label="$t('stepTwo.optionalText.name')"
                              :placeholder="$t('stepTwo.optionalText.name')"
                              outlined
                              readonly
                              dense
                              class="mb-2"
                              v-model="application.comment"
                            ></v-textarea>
                          </v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col class="row mt-1 ml-0" @click="previous(3)">
                            <v-btn color="success" class="ml-auto">{{
                              $t("buttonsForm.modify.name")
                            }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <h2 class="mtitle">
                      <span v-html="$t('stepThree.title2')"></span>
                    </h2>
                    <v-row class="mb-5">
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepThree.stayAddress.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="journey.stayAddress"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepThree.stayCity.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="journey.stayCity"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepThree.stayPhone.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="journey.stayPhone"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              :label="$t('stepThree.identityPicture.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getFileName(files.photo)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepThree.passportPicture.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getFileName(files.passport)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                        <v-row class="mt-0">
                          <v-col
                            ><v-text-field
                              :label="$t('stepThree.invitationPicture.name')"
                              outlined
                              dense
                              readonly
                              filled
                              hide-details="auto"
                              :value="getFileName(files.ticket)"
                            ></v-text-field
                          ></v-col>
                        </v-row>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-text-field
                              filled
                              auto-grow
                              rows="4"
                              :label="$t('stepThree.invitationLetter.name')"
                              outlined
                              readonly
                              dense
                              class="mb-2"
                              :value="getFileName(files.reservation)"
                            ></v-text-field>
                          </v-col>
                        </v-row>

                        <v-row class="mt-0">
                          <v-col class="row mt-1 ml-0" @click="previous(4)">
                            <v-btn color="success" class="ml-auto">{{
                              $t("buttonsForm.modify.name")
                            }}</v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col>
                        <v-checkbox
                          v-model="termAgree"
                          :label="$t('resumeStep.declaration')"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                    <v-row class="pt-5">
                      <v-btn @click="previous(4)">
                        {{ $t("buttonsForm.previous.name") }}
                      </v-btn>
                      <v-btn
                        color="success"
                        class="ml-auto"
                        @click="createApplication"
                      >
                        {{ $t("paymentStep.title") }}
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-card>
        </v-col>

        <v-col md="2" v-show="showBox">
          <div class="mt-1 ask">
            <v-avatar outlined color="#3e8ead">
              <img src="../assets/intero.png" alt="John" />
            </v-avatar>
          </div>
          <v-card
            class="mx-auto mt-0 px-2 py-1"
            tile
            color="#008771"
            width="300"
          >
            <v-card-text>
              <p class="white--text text-justify mt-8" style="font-size: 14px">
                {{ infotext }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>

<script>
import Passeport from "@/models/Passeport";
import Journey from "@/models/Journey";
import Contact from "@/models/Contact";
import Applicant from "@/models/Applicant";
import Application from "@/models/Application";
import CountryService from "@/services/CountryService";
import FileService from "@/services/FileService";
import FooterInfo from "@/components/FooterInfo";
import { dateDiff, todayDiff } from "@/mixin/DateHelper";
import { mapActions, mapGetters } from "vuex";


export default {
  name: "ApplicationPage",
  components: {
    FooterInfo,
  },
  data() {
    return {
      infotext: this.$t("stepOne.helpText"),
      showBox: true,
      step: 1,
      alert: false,
      errorMessage: "",
      fileRules: [
        (v) => !v || v.size < 1000000 || this.$t("stepThree.fileSizeExceeded"),
        (v) => !!v || this.$t("requiredField"),
        (v) => /.+(\.jpg|\.png|\.pdf)$/.test(v.name) || this.$t("stepThree.fileDesc.identityPicture"),
      ],
      imgRules: [
        (v) => !v || v.size < 1000000 || this.$t("stepThree.fileSizeExceeded"),
        (v) => !!v || this.$t("requiredField"),
        (v) => /.+(\.jpg|\.png)$/.test(v.name) || this.$t("stepThree.fileDesc.identityPicture"),
      ],
      show: false,
      infoValid: true,
      compValid: true,
      journeyValid: true,
      addrValid: true,
      requiredRules: [(v) => !!v || this.$t("requiredField")],
      numberRequiredRules: [
        (v) => !!v || this.$t("requiredField"),
        (v) => !isNaN(v) || "Ce champ doit contenir des chiffres",
      ],
      numberRules: [
        (v) => (v == "" && !isNaN(v)) || "Ce champ doit contenir des chiffres",
      ],
      mailRules: [
        (v) => !!v || "E-mail est obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit etre valide",
      ],
    
      passeport: new Passeport(),
      termAgree: false,
      journey: new Journey(),
      contact: new Contact(),
      applicant: new Applicant(),
      application: new Application(),
      files: {
        passport: null,
        photo: null,
        ticket: null,
        reservation: null,
      },
      mFiles: [],
      trackinNumber: null,
      countries: [],
      confirmMail: "",
      birthDateMenu: false,
      delivryMenu: false,
      expiredMenu: false,
      menuEnter: false,
      menuExit: false,
      activePicker: null,
    };
  },
  methods: {
    ...mapActions("data", {
      loadProducts: "loadProducts",
      saveApplication: "saveApplication",
    }),

    async uploadFiles() {
      await FileService.uploadFiles(this.files).then((response) => {
        this.mFiles = response.data;
      });
    },

    findFile(type) {
      console.log(this.mFiles);
      let find = this.mFiles.find((e) => e.type == type);
      console.log(type, find);
      return find.id;
    },

    checkTransit() {
      if (
        dateDiff(this.journey.exitDate, this.journey.entryDate) > 14 &&
        this.journey.reason == "transit"
      ) {
        return false;
      }
      return true;
    },

    getGender(value){
      if(value == "M"){
        return this.$t('stepTwo.genderMale')
      }
      return this.$t('stepTwo.genderFemale')
    },


    getReason(value){
      let find = this.$t('stepOne.reasonList').find((e) => e.value == value)
      return find === undefined ? "": find.text
    },

    getMarital(value){
      let find = this.$t('stepTwo.FamilySituationList').find((e) => e.value == value)
      return find === undefined ? "": find.text
    },

    getProduct() {
      let find = null;
      if (dateDiff(this.journey.exitDate, this.journey.entryDate) > 14) {
        find = this.getterProducts.find((e) => e.name == 'VCS');
      } else {
        find = this.getterProducts.find((e) => e.name == 'VT');
      }
      if (find !== undefined) {
        return find.id;
      }
      return null;
    },

    async createApplication() {
      if (this.termAgree) {
        await this.uploadFiles();
        this.journey.reservation = this.findFile("RESERVATION");
        this.applicant.photo = this.findFile("PHOTO");
        this.application.ticket = this.findFile("TICKET");
        this.passeport.passport = this.findFile("PASSPORT");
        let payload = {
          application: this.application,
          applicant: this.applicant,
          passport: this.passeport,
          journey: this.journey,
          contact: this.contact,
          product: this.getProduct(),
        };
        await this.saveApplication(payload);
        this.$router.push("/checkout");
      }
    },

    getLocale() {
      return this.$i18n.locale.toUpperCase();
    },
    getFileName(file) {
      if (file !== undefined && file != null) {
        return file.name;
      }
    },
    savebirthDate(date) {
      this.$refs.birthDateMenu.save(date);
    },
    saveEnterDate(date) {
      this.$refs.menuEnter.save(date);
    },
    saveExitDate(date) {
      this.$refs.menuExit.save(date);
    },
    saveDelivryDate(date) {
      this.$refs.delivryMenu.save(date);
    },
    saveExpiredDate(date) {
      this.$refs.expiredMenu.save(date);
    },

    loadCountries() {
      CountryService.loadCountries().then((response) => {
        this.countries = response.data;
      });
    },

    getCountry(id) {
      let find = this.countries.find((e) => e.id == id);

      if (find !== undefined) {
        return find.fr;
      }
      return "";
    },

    previous(step) {
      this.step = step;
      switch (step) {
        case 1:
          this.showBox = true;
          this.infotext = this.$t("stepOne.helpText");
          break;
        case 2:
          this.showBox = true;
          this.infotext =
            this.$t("stepTwo.helpText") + " " + this.$t("stepTwo.helpText");
          break;
        case 3:
          this.showBox = true;
          this.infotext =
            this.$t("stepTwo.helpText") + " " + this.$t("stepTwo.helpText");
          break;
        case 4:
          this.showBox = true;
          this.infotext = this.$t("stepThree.helpText");
          break;
        default:
          this.showBox = false;
      }
    },

    validatePerso() {
      if (this.$refs.personalInfo.validate()) {
        this.infoValid = true;
        this.step = 3;
        this.infotext =
          this.$t("stepTwo.helpText") + " " + this.$t("stepTwo.helpText");
      } else {
        this.infoValid = false;
      }
    },
    validateCompl() {
      if (this.$refs.compInfo.validate()) {
        this.compValid = true;
        this.step = 4;
        this.infotext = this.$t("stepThree.helpText");
      } else {
        this.compValid = false;
      }
    },

    checkJourneyDate() {
      if (dateDiff(this.journey.exitDate, this.journey.entryDate) < 1) {
        return false;
      }
      return true;
    },

    validateJourney() {
      if (this.$refs.journeyInfo.validate()) {
        if (
          dateDiff(this.passeport.expiredDate, this.journey.entryDate) > 180 &&
          todayDiff(this.journey.entryDate) < 180
        ) {
          if (this.checkJourneyDate()) {
            if (this.checkTransit()) {
              this.journeyValid = true;
              this.step = 2;
              this.alert = false;
              this.infotext =
                this.$t("stepTwo.helpText") + " " + this.$t("stepTwo.helpText");
            } else {
              this.journeyValid = false;
              this.alert = true;
              this.errorMessage = this.$t("stepOne.errors.transitErrorMessage");
            }
          } else {
            this.journeyValid = false;
            this.alert = true;
            this.errorMessage = this.$t(
              "stepOne.errors.journeyDateErrorMessage"
            );
          }
        } else {
          this.journeyValid = false;
          this.alert = true;
          this.errorMessage = this.$t("stepOne.passportDateError");
        }
      } else {
        this.journeyValid = false;
      }
    },
    validateAddr() {
      if (this.$refs.addrInfo.validate()) {
        this.addrValid = true;
        this.step = 5;
        this.showBox = false;
      } else {
        this.addrValid = false;
      }
    },
    delivryDateChoice() {
      if (this.delivryDate > new Date().now) {
        this.show = true;
      }
    },
  },

  mounted() {
    this.loadProducts();
    this.loadCountries();
  },
  computed: {
    ...mapGetters("data", {
      getterProducts: "getProducts",
    }),
    cmailRules() {
      return [
        (value) => !!value || "La confirmation de mail est obligatoire",
        (value) =>
          value === this.applicant.email || "Le mail ne correspond pas.",
      ];
    },
  },
};
</script>

<style scoped>
.lineStep {
  border-top: 1px solid #3f8ead;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.ask {
  z-index: 20;
  position: relative;
  top: 20px;
  left: 40%;
}

.mtitle {
  color: rgba(55, 55, 56, 0.664);
  border-bottom: rgba(55, 55, 56, 0.664) 2px solid;
  margin-bottom: 10px;
}

.main-content {
  margin-left: 330px;
}
</style>