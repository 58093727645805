<template>
    <div class="cover ">
        {{maintenanceText}}
    </div>
</template>

<script>

export default ({
    name: "MaintenancePage",
    data() {
        return {
            maintenanceText: "Ce site est en maintenance"
        }
    },
})
</script>

<style scoped>
.cover{
    padding-top: 50px;
    text-align: center;
    font-size: 36px;
    color: white;
    font-weight: bolder;
    width: 100%;
    height: 100%;
    background-color: rgb(94, 170, 222);
    background-image: url('../assets/maintenance.png');
    background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size:40%;
}
</style>
