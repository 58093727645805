<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <b>{{nbre}}. {{titre}}</b>
      </v-expansion-panel-header>
      <v-expansion-panel-content >
        {{contenu}}
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
<script>
export default {
    props: {
      nbre: Number,
      titre: String,
      contenu: String
    },
}
</script>
<style scoped>
* {
    color: #777;
}
  

  

</style>