<template>
  <v-row>
    <v-dialog v-model="dialog" max-width="700px">
      <v-sheet color="#fff">
        <contact-form></contact-form>
        <div class="">
          <v-btn block color="default" @click="dialog=false">{{
          $t("footerContact.cancel")
        }}</v-btn>
        </div>
        
      </v-sheet>
    </v-dialog>
    <v-row class="footerInfo">
      <v-row class="text-center">
        <div class="col py-0">{{ $t("footer.repDjibouti") }}</div>
        <div class="col py-0">{{ $t("footer.faq") }}</div>
        <div class="col py-0">EVISA DJIBOUTI</div>
        <div class="col py-0">{{ $t("footer.contact") }}</div>
        <div class="col py-0">{{ $t("footer.mentions") }}</div>
      </v-row>

      <v-row class="info-inner">
        <v-row class="col d-flex align-center mt-0 justify-center py-1">
          <a
            href="https://www.presidence.dj/"
            target="_blank"
            to="/information"
          >
            {{ $t("footer.repDjiboutiLien1") }}<br />
            <hr />
            {{ $t("footer.repDjiboutiLien2") }}
          </a>
        </v-row>
        <v-row class="col d-flex align-center mt-0 justify-center py-1">
          <router-link to="/faq">
            {{ $t("footer.faqTitle") }}
          </router-link>
        </v-row>
        <v-row class="col d-flex align-center mt-0 justify-center py-1 mt-0">
          <img height="55" src="../assets/djibouti-simple.png" />
        </v-row>
        <v-row
          class="col d-flex align-center mt-0 justify-center"
          @click="dialog = true"
        >
          <router-link to="#">
            {{ $t("footer.contactMail") }}
          </router-link>
        </v-row>
        <v-row class="col d-flex align-center mt-0 justify-center">
          <router-link to="/mentions">
            {{ $t("footer.mentionsLink") }}
          </router-link>
        </v-row>
      </v-row>
    </v-row>
    <v-row>
      <partener></partener>
    </v-row>
  </v-row>
</template>
<script>
import partener from "@/components/Partener";
import ContactForm from "@/components/ContactForm";

export default {
  name: "HomeFooter",
  data: () => ({
    dialog: false,
  }),

  components: {
    partener,
    ContactForm,
  },
};
</script>
<style scoped>
.footerInfo {
  display: block;
  width: 100%;
  font-size: 14px;
}

div {
  margin: 0px;
}
.info-inner {
  background-color: #3a3a3a;
  padding: 0px;
}

.v-application a {
  text-decoration: none;
  color: #ffffff;
}

a:hover {
  color: #45a6b9;
}
</style>