import Vuex from 'vuex'
import Vue from 'vue'
import dataModule from './modules/data'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules:{
       data: dataModule
    }
})

export default store