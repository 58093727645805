<template>
  <v-layout>
    <v-main>
      <v-row class="px-3">
        <!-- Debut boite de Dialog des représentations -->
        <v-dialog v-model="dialog" max-width="600px">
          <v-sheet color="#fff">
            <v-card>
              <v-card-title>
                <span class="ml-2 mt-2">{{
                  $t("stepPassType.infoHelpModalTitle")
                }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <p class="black--text ml-5 mr-3">
                    {{ $t("stepPassType.infoHelpModalText") }}
                  </p>
                </v-row>
                <v-row class="col d-flex align-center mt-0">
                  <router-link class="lien ml-3" to="/mentions">
                    <strong>{{ $t("stepPassType.infoHelpModalLink") }}</strong>
                  </router-link>
                </v-row>
                <v-row>
                  <ol id="example-2">
                    <strong
                      ><li
                        class="black--text ml-10"
                        v-for="city in this.$t(
                          'stepPassType.infoHelpModalList'
                        )"
                        :key="city.cityName"
                      >
                        {{ city.cityName }}
                      </li></strong
                    >
                  </ol>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog = false">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-dialog>
        <!-- Fin boite de Dialog Liste des représentations -->

        <!-- Debut boite de Dialog Liste des programmes -->
        <v-dialog v-model="dialog1" max-width="650px">
          <v-sheet color="#fff">
            <v-card>
              <v-card-title>
                <span class="ml-2 mt-2">{{
                  $t("stepPassType.infoTitleModalTitle")
                }}</span>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <ol>
                  <li
                    class="progtext black--text ml-5 mr-2 mt-1"
                    v-for="program in this.$t(
                      'stepPassType.infoTitle2ModalList'
                    )"
                    :key="program"
                  >
                    {{ program.info }}
                  </li>
                </ol>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green darken-1" text @click="dialog1 = false">
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-sheet>
        </v-dialog>
        <!-- Fin boite de Dialog Liste des programmes -->

        <v-col md="8" offset-md="2">
          <div class="mt-2">
            <v-card class="mt-2" color="#f1f1f1" outlined rounded-0 >
              <v-card-title>
                <v-sheet class="col text-center" color="#3e8ead" height="50px">
                  <p class="headline white--text">
                    {{ $t("stepPassType.title") }}
                  </p>
                </v-sheet>
              </v-card-title>

              <v-card-text>
                <div class="row">
                  <div class="col">
                    <v-card  min-width="250">
                      <v-card-text>
                        <v-row class="question">
                          {{ $t("stepPassType.infoTitle1") }}
                        </v-row>
                        <v-row class="answer">
                          {{ $t("stepPassType.infoTitle1Text") }}
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col">
                    <v-card min-width="250">
                      <v-card-text>
                        <v-row class="question">
                          {{ $t("stepPassType.infoTitle2") }}
                        </v-row>
                        <v-row class="answer">
                          <p>
                            {{ $t("stepPassType.infoTitle2Text") }}<br />{{
                              $t("stepPassType.infoTitle2Text2")
                            }}<a class="white--text" @click="dialog1 = true">
                              <strong> {{ $t("stepPassType.here") }}</strong>
                            </a>
                          </p>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                  <div class="col">
                    <v-card  min-width="250">
                      <v-card-text>
                        <v-row class="question">
                          {{ $t("stepPassType.infoTitle3") }}
                        </v-row>

                        <v-row class="answer">
                          {{ $t("stepPassType.infoTitle3Text") }}
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-container class="text-right mt-4">
                  <v-btn
                    right
                    class="ma-2 white--text"
                    rounded
                    color="#008771"
                    to="/demande-visa"
                  >
                    {{ $t("buttonsForm.continue.name") }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-container>
              </v-card-actions>
            </v-card>
          </div>
        </v-col>
        <v-col md="2">
          <div class="mt-1 ask">
            <v-avatar outlined color="#3e8ead">
              <img src="../assets/intero.png" alt="John" />
            </v-avatar>
          </div>
          <v-card
            class="mx-auto mt-0 px-2 py-1"
            tile
            color="#008771"
            width="300"
          >
            <v-card-text>
              <p class="white--text text-justify mt-8" style="font-size: 14px">
                {{ $t("stepPassType.infoHelpText") }}
                {{ $t("stepPassType.infoHelpText2") }}
                <a class="white--text" @click="dialog = true"
                  ><strong>{{ $t("stepPassType.here") }}</strong></a
                >
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>
<script>
import FooterInfo from "@/components/FooterInfo";
export default {
  name: "Information",
  data: () => ({
    dialog: false,
    dialog1: false,
  }),
  components: {
    FooterInfo,
  },
};
</script>
<style scoped>
.main-content {
  width: 1200px;
}

.progtext {
  font-size: 16px;
}

.answer {
  background-color: #3e8ead;
  min-height: 130px;
  padding: 0px;
  color: #fff;
  font-size: 15px;
  padding: 15px;
}

.lien {
  text-decoration: none;
  color: #007bff;
}

.ask {
  z-index: 20;
  position: relative;
  top: 20px;
  left: 40%;
}

.question {
  min-height: 120px;
  font-size: 18px;
  color: #000;
  font-weight: bolder;
  padding: 15px;
  text-align: justify;
  border: 2px solid #3e8ead;
}
</style>