<template>
  <v-app >
    <Header></Header>
    <router-view ></router-view>
  </v-app>
</template>

<script>
import Header from "./components/Header.vue";

export default {
  name: "App",

  components: {
    Header,
  },

  data: () => ({
    disclaimer: {
      value: false,
      text: "This is an disclamer",
      color: "red",
      dark: true,
    },
  }),
};
</script>


<style scoped>
.main {
  display: block;
  height: 100%;
}
</style>