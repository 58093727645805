import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import VueMoment from 'vue-moment'
import VueI18n from 'vue-i18n'
import '@/assets/style/main.css'
import FR from './locales/fr'
import EN from './locales/en'
import store from './store/index'

Vue.use(VueI18n);
Vue.use(VueMoment);
Vue.config.productionTip = false

const messages = {
  EN: EN,
  FR: FR,
};

const i18n = new VueI18n({
  locale: 'FR',
  messages
});



new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
