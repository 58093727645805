import axios from 'axios'

const API_URL = process.env.VUE_APP_MSCORE_URL

class ApplicationService{
    createApplication(payload){
        return axios.post(`${API_URL}/application`, payload)
    }

    checkStatus(payload){
        return axios.post(`${API_URL}/application/check`, payload)
    }
}

export default new ApplicationService()