import axios from 'axios'

const API_URL = process.env.VUE_APP_MSFAQ_URL

class FaqService{
    loadFaqs(locale){
        return axios.get(`${API_URL}/faqs/${locale}`)
    }
}

export default new FaqService()