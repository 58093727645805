<template>
  <v-layout>
    <v-main>
      <v-row class="justify-center mt-5">
        <v-card width="1175px" class="mt-15" flat>
          <v-alert border="bottom" colored-border type="success" elevation="2">
            <h2 class="title">{{ $t("finalStep.applicationTitle") }}</h2>
            <div>
              <span v-html="$t('finalStep.applicationText')"></span>
              <span class="font-weight-bold">
                {{ getterApplication.referenceId }}
              </span>
            </div>
          </v-alert>
        </v-card>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import FooterInfo from "@/components/FooterInfo";
export default {
  components: {
    FooterInfo,
  },
  methods: {
    getMailLink() {
      return "mailto:" + this.$t("finalStep.warningLink");
    },
    redirect() {
      if (this.getterApplication.referenceId == "") {
        this.$router.push("/");
      }
    },
  },
  mounted() {
    this.redirect();
  },
  computed: {
    ...mapGetters("data", {
      getterApplication: "getApplication",
    }),
  },
};
</script>