<template>
  <v-layout>
    <v-main>
      <v-row class="justify-center mt-5">
        <v-card width="1175px" class="mt-15" outlined color="#f1f1f1">
          <v-card-text>
            <v-alert
              border="bottom"
              v-model="alert"
              dense
              color="red"
              colored-border
              type="error"
              elevation="2"
              dismissible
            >
              <div class="text-h6">{{ $t("finalStep.warningTitle") }}</div>
              <div>
                {{ $t("finalStep.warningText") }}
                <b>{{ $t("finalStep.warningLink") }}</b>
              </div>
            </v-alert>
            <h2 class="evisa-header mb-5">{{ $t("paymentStep.title") }}</h2>
            <div class="text-center">
              <p>
                {{ $t("paymentStep.text1") }}
              </p>
              <p class="font-weight-bold">
                {{ $t("paymentStep.text2") }}
              </p>
              <p>
                {{ $t("paymentStep.text3") }}
              </p>
            </div>
            <div>
              <v-row>
                <v-col>
                  <v-img
                    max-width="300"
                    :aspect-ratio="16 / 12"
                    src="@/assets/payment_americanExpressCard.png"
                  ></v-img>
                </v-col>
                <v-col>
                  <v-img
                    max-width="300"
                    :aspect-ratio="16 / 12"
                    src="@/assets/payment_masterCard.png"
                  ></v-img>
                </v-col>
                <v-col>
                  <v-img
                    max-width="300"
                    :aspect-ratio="16 / 12"
                    src="@/assets/payment_visaCard.png"
                  ></v-img>
                </v-col>
              </v-row>
            </div>
            <div class="text-center mt-5">
              <v-btn
                :loading="loading"
                :disabled="pay"
                @click="payment()"
                width="400"
                color="success"
                tile
                class="mr-15"
                >{{ $t("paymentStep.title") }}</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>

<script>
import FooterInfo from "@/components/FooterInfo";
import PaymentService from "@/services/PaymentService";
import { mapGetters } from "vuex";
export default {
  components: {
    FooterInfo,
  },
  data() {
    return {
      loading: true,
      payUrl: "#",
      pay: true,
      alert: false,
    };
  },
  methods: {
    payment() {
      window.location = this.payUrl;
    },
    checkout(payload) {
      PaymentService.checkout(payload)
        .then((response) => {
          this.payUrl = response.data.url;
          (this.pay = false), (this.loading = false);
        })
        .catch(() => {
          this.alert = true;
          this.loading = false;
        });
    },
    checkUrl() {
      console.log(this.getPayStatus);
      if (this.getPayStatus) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.checkout(this.getterApplication);
    this.checkUrl();
  },
  computed: {
    ...mapGetters("data", {
      getterApplication: "getApplication",
      getPaymentUrl: "getPaymentUrl",
      getPayStatus: "isPayFailed",
    }),
  },
};
</script>