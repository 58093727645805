<template>
  <v-layout>
    <v-main>
      <v-row class="justify-center mt-5">
        <v-card width="1175px" class="mt-15" flat>
          <v-alert border="bottom" colored-border type="error" elevation="2">
            <h2 class="title">{{ $t("finalStep.warningTitle") }}</h2>
            <div>
              {{ $t("finalStep.warningText") }}
              <a
                :href="getMailLink()"
                class="text-decoration-none font-weight-bold"
              >
                {{ $t("finalStep.warningLink") }}
              </a>
            </div>
          </v-alert>
        </v-card>
      </v-row>
    </v-main>
    <v-footer app dark class="px-0">
      <footer-info></footer-info>
    </v-footer>
  </v-layout>
</template>

<script>
import FooterInfo from "@/components/FooterInfo";
export default {
  components: {
    FooterInfo
  },
  data() {
    return {
      errorPage: false,
    };
  },
  methods: {
    getMailLink() {
      return "mailto:" + this.$t("finalStep.warningLink");
    },
  },
};
</script>