import axios from 'axios'

const API_URL = process.env.VUE_APP_MSDOC_URL

class FileService{
    uploadFiles(files){
        let formData = new FormData();
        formData.append("photo", files.photo);
        formData.append("ticket", files.ticket);
        formData.append("reservation", files.reservation);
        formData.append("passport", files.passport);
        return axios.post(`${API_URL}/uploads`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }
}

export default new FileService()