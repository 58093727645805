import axios from 'axios'

const API_URL = process.env.VUE_APP_MSCORE_URL

class CountryService{

    loadCountries(){
        return axios.get(`${API_URL}/countries`)
    }

    findById(id){
        return axios.get(`${API_URL}/country/${id}`)
    }

    findByCode(code){
        return axios.get(`${API_URL}/country/code/${code}`)
    }
}

export default new CountryService()