<template>
<v-layout>
   <v-main>
    <v-card width="1175px" class="mx-auto my-5">
      <v-card elevation="0" color="#f1f1f1">
        <v-card-title color="#f1f1f1">
          <v-sheet class="text-center" width="100%" color="#3f8ead">
            <p class="mx-auto white--text mt-4">{{ $t("faqTitle.title") }}</p>
          </v-sheet>
        </v-card-title>
        <v-card-text v-for="faq in faqs" :key="faq.id" class="pt-0">
          <Faq :titre="faq.question" :nbre="faq.number" :contenu="faq.answer" />
        </v-card-text>
      </v-card>
    </v-card>
  </v-main>
  <v-footer app dark class="px-0">
    <footer-info></footer-info>
  </v-footer>
</v-layout>
 
</template>
<script>
import Faq from "@/components/Faq.vue";
import FooterInfo from "@/components/FooterInfo"
import FaqService from "@/services/FaqService";

export default {
  data: () => ({
    faqs: [],
  }),
  components: {
    Faq,
    FooterInfo
  },
  methods: {
    loadFaq(lang) {
      FaqService.loadFaqs(lang).then((response) => {
        this.faqs = response.data;
      });
    },

    getLocale() {
      return this.$i18n.locale;
    },
  },

  mounted() {
    this.loadFaq(this.$i18n.locale);
  },
  watch: {
    "$i18n.locale": function (value) {
      this.loadFaq(value);
    },
  },
};
</script>