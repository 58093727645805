<template>
  <v-layout class="footerInfo">
    <v-row  @mouseenter="toggle" @mouseleave="toggle" >
      <p class="mx-auto  justify-center"> {{$t('footer.partners')}}</p>
    </v-row>
    <v-row class="d-flex justify-center info-inner" v-if="show">
      <img
        class="mr-8"
        src="../assets/eVisa_logo_partenaire_3.jpg"
        alt="Union européenne"
      />

      <img
        class="mr-8"
        src="../assets/eVisa_logo_partenaire_1.jpg"
        alt="Coopération allemande"
      />

      <img
        class="mr-8"
        src="../assets/eVisa_logo_partenaire_4.jpg"
        alt="Union européenne"
      />

      <img
        class=""
        src="../assets/eVisa_logo_partenaire_2.jpg"
        alt="Union européenne"
      />
    </v-row>
  </v-layout>
</template>
<script>
export default {
  name: "Partener",
  data() {
    return {
      show: false
    };
  },
  methods: {
    toggle(){
      this.show = !this.show
    }
  }
};
</script>


<style scoped>
img {
  height: 80px;
}

.footerInfo {
  display: block;
  width: 100%;
  font-size: 14px;
}

.info-inner{
  background-color: #3a3a3a;
  margin-top: 0px;
}
</style>