export default class Contact{
    constructor(city, cp, label, phone, state, line, type){
        this.city = city
        this.cp = cp
        this.label = label,
        this.phone = phone
        this.state = state
        this.line = line
        this.type = type
    }
}