<template>
  <v-row class="d-flex justify-center">
    <v-btn color="#008771" dark large tile class="mr-4" to="/suivi-demande">
      {{$t('home.suivi')}}
    </v-btn>
    <v-btn
      dark
      large
      tile
      class="mr-4"
      to="/information"
      color="#3f8ead"
    >
      {{$t('home.demande')}}
    </v-btn>
    <v-btn color="#008771" dark large tile to="/faq">
       {{$t('home.infos')}}
    </v-btn>
  </v-row>
</template>

<script>
export default {
  name: "CarousselBtn",
};
</script>
